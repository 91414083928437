import BaseInstance from "../base.instance";
import {
  MESSAGE_LIST,
  MESSAGE_DETAIL,
  MESSAGE_SAVE,
  MESSAGE_DELETE,
  MESSAGE_INBOX_LIST,
  MESSAGE_INBOX_DETAIL,
  MESSAGE_INBOX_DELETE
} from "../constants";

const MessageService = {
  getList(data) {
    return BaseInstance.post(MESSAGE_LIST, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(MESSAGE_DETAIL, id);
  },
  save(data) {
    return BaseInstance.create(MESSAGE_SAVE, 0, data);
  },
  delete(params) {
    return BaseInstance.remove(MESSAGE_DELETE, { params });
  },
  getListInbox(data) {
    return BaseInstance.post(MESSAGE_INBOX_LIST, data);
  },
  getDetailInbox(id) {
    return BaseInstance.fetch(MESSAGE_INBOX_DETAIL, id);
  },
  deleteInbox(params) {
    return BaseInstance.remove(MESSAGE_INBOX_DELETE, { params });
  }
};

export default MessageService;
