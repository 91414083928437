<template>
  <v-card>
    <v-row class="mx-0">
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="px-0"
        style="border-right: 1px solid #E0E0E0"
      >
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="isCreate ? 'error' : '#FBB005'"
            class="px-10"
            @click="
              isCreate = !isCreate;
              isChange = true;
              isUpdate = false;
            "
          >
            <span class="subtitle-2 text-capitalize">{{
              isCreate ? "Cancel" : "Buat Pesan"
            }}</span>
          </v-btn>
          <v-spacer />
        </v-card-actions>
        <v-divider class="mt-3" />
        <v-list class="mx-1" id="list-custom" dense>
          <v-list-item-group v-model="type" color="#3B86FF" mandatory>
            <v-list-item
              :value="item.value"
              v-for="(item, index) in types"
              :key="index"
            >
              <v-list-item-content>
                <span class="caption">{{ item.text }}</span>
              </v-list-item-content>
              <v-list-item-action>
                <span class="caption" v-if="item.new > 0">{{ item.new }}</span>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <template v-if="isCreate">
        <v-col
          cols="12"
          xl="10"
          lg="10"
          md="10"
          sm="12"
          xs="12"
          class="pa-5"
          id="card-custom"
        >
          <v-form
            id="form"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-autocomplete
              :items="receivers"
              :loading="loadingReceiver"
              v-model="form.receivers"
              placeholder="Kepada"
              :search-input.sync="searchReceiver"
              cache-items
              multiple
              outlined
              dense
              :rules="[v => !!v || 'Penerima harus diisi']"
              item-text="username"
              item-value="id"
            ></v-autocomplete>
            <v-text-field
              v-model="form.subject"
              placeholder="Subjek"
              outlined
              dense
            ></v-text-field>
            <vue-editor v-model="form.content"></vue-editor>
            <div v-show="!isChange && form.attachment">
              <p class="subtitle-2 mt-5">Attachment</p>
              <v-img
                class="mt-4 mb-2 text-left"
                src="@/assets/images/upload.webp"
                alt="Upload"
                width="75"
                contain
              />
              <p class="ma-0">
                <a
                  :href="form.attachment"
                  target="_blank"
                  class="subtitle-2 hover-primary"
                >
                  UNDUH
                </a>
              </p>
              <p class="ma-0">
                <a
                  @click="() => (isChange = true)"
                  class="subtitle-2 hover-primary"
                >
                  UBAH
                </a>
              </p>
              <v-divider class="my-5" />
            </div>
            <FileDraggable
              ref="fileDraggable"
              v-show="isChange"
              :closeable="isUpdate"
              :handleClose="handleClose"
              class="mt-7"
              @on-change="handleOnChange"
            />
            <v-card-actions class="mt-5">
              <v-spacer />
              <v-btn
                color="info"
                class="px-10 mr-2 subtitle-2 text-capitalize"
                @click="saveToDraft"
                :loading="formLoading"
                :disabled="!valid"
                form="form"
                >Simpan ke Draft
              </v-btn>
              <v-btn
                color="#FBB005"
                class="px-10 subtitle-2 text-capitalize"
                :loading="formLoading"
                type="submit"
                :disabled="!valid"
                form="form"
                >Kirim
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="3"
          sm="12"
          xs="12"
          class="px-0"
          style="border-right: 1px solid #E0E0E0"
        >
          <v-card-actions id="card-custom">
            <v-text-field
              solo
              flat
              dense
              v-model="search"
              placeholder="Cari Pesan atau Nama"
              hide-details
              prepend-inner-icon="search"
            ></v-text-field>
          </v-card-actions>
          <LoaderNotFound
            v-if="loading || chats.length == 0"
            message="Tidak ada pesan."
            :loading="loading"
            :size="35"
            :px="2"
            :py="4"
          />
          <template v-else>
            <v-list
              :height="`${height}px`"
              style="overflow: scroll"
              v-scroll.self="onScroll"
            >
              <v-list-item-group v-model="chat" color="#525561">
                <v-list-item
                  :value="item.inbox_id || item.id"
                  v-for="(item, index) in chats"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-img
                      v-if="item.sender.photo"
                      :src="item.sender.photo"
                    ></v-img>
                    <v-img v-else src="@/assets/images/person.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="subtitle-2"
                      v-text="item.sender.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle class="caption">
                      <span v-if="item.subject != 'null'">{{
                        item.subject
                      }}</span>
                      <span v-else class="text-subtitle"
                        >(tidak ada judul)</span
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <span class="caption font-size-small">{{
                      item.message_date
                    }}</span>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
        </v-col>
        <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12" class="pa-5">
          <LoaderNotFound
            v-if="loadingDetail || selected == null"
            message="Tidak ada isi pesan."
            :loading="loadingDetail"
            :size="35"
            :px="2"
            :py="4"
          />
          <template v-else>
            <v-card-title>
              <span v-if="selected.subject != 'null'" class="title">{{
                selected.subject
              }}</span>
              <span v-else class="title text-subtitle">(tidak ada judul)</span>
              <v-spacer></v-spacer>
              <v-menu bottom left offset-x v-if="type != 'DELETED'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    @click="handleSent(selected)"
                    v-if="type == 'DRAFT'"
                  >
                    <v-list-item-title>Kirim Ulang</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDelete(selected.id)">
                    <v-list-item-title>Hapus Pesan</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-list-item v-if="type == 'INBOX'">
              <v-list-item-avatar>
                <v-img
                  v-if="selected.sender.photo"
                  :src="item.sender.photo"
                ></v-img>
                <v-img v-else src="@/assets/images/person.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="subtitle-2">{{
                  selected.sender.name
                }}</v-list-item-title>
                <v-list-item-subtitle class="caption">
                  <span v-if="selected.subject != 'null'">{{
                    selected.subject
                  }}</span>
                  <span v-else class="text-subtitle">(tidak ada judul)</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <span class="caption font-size-small">{{
                  selected.message_date
                }}</span>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-else
              v-for="(item, index) in selected.receiver"
              :key="index"
            >
              <v-list-item-avatar>
                <v-img v-if="item.photo" :src="item.photo"></v-img>
                <v-img v-else src="@/assets/images/person.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="subtitle-2">{{
                  item.name
                }}</v-list-item-title>
                <v-list-item-subtitle class="caption">
                  <span v-if="item.email">{{ item.email }}</span>
                  <span v-else class="text-subtitle">(tidak ada email)</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <span class="caption font-size-small">{{
                  selected.message_date
                }}</span>
              </v-list-item-icon>
            </v-list-item>
            <v-divider class="my-5" />
            <p
              v-if="selected.content != 'null'"
              class="text-justify subtitle-2 font-weight-regular"
              v-html="selected.content"
            ></p>
            <p
              v-else
              class="text-justify subtitle-2 font-weight-regular text-subtitle"
            >
              (tidak ada konten)
            </p>
            <v-divider class="my-5" />
            <template v-if="selected.attachment">
              <p class="subtitle-2">Attachment</p>
              <v-img
                class="mt-4 mb-2 text-left"
                src="@/assets/images/upload.webp"
                alt="Upload"
                width="75"
                contain
              />
              <a
                :href="selected.attachment"
                target="_blank"
                class="subtitle-2 hover-primary"
              >
                UNDUH
              </a>
              <v-divider class="my-5" />
            </template>
            <v-btn
              v-if="type == 'INBOX'"
              :color="isReply ? 'error' : '#FBB005'"
              class="px-10"
              @click="isReply = !isReply"
            >
              <span class="subtitle-2 text-capitalize">{{
                isReply ? "Cancel" : "Reply"
              }}</span>
            </v-btn>
            <template v-if="isReply">
              <v-form
                id="formReply"
                ref="formReply"
                v-model="validReply"
                lazy-validation
                @submit.prevent="save('reply')"
              >
                <v-text-field
                  class="mt-5"
                  v-model="form.receivers.name"
                  placeholder="Kepada"
                  outlined
                  disabled
                  hide-details
                  dense
                ></v-text-field>
                <v-text-field
                  class="mt-5"
                  v-model="form.subject"
                  placeholder="Subjek"
                  outlined
                  dense
                ></v-text-field>
                <vue-editor v-model="form.content"></vue-editor>
                <FileDraggable
                  ref="fileDraggable"
                  class="mt-5"
                  @on-change="handleOnChange"
                />
                <v-card-actions class="mt-5">
                  <v-spacer />
                  <v-btn
                    color="info"
                    class="px-10 mr-2 subtitle-2 text-capitalize"
                    @click="saveToDraft('reply')"
                    :loading="formLoading"
                    :disabled="!validReply"
                    form="formReply"
                    >Simpan ke Draft
                  </v-btn>
                  <v-btn
                    color="#FBB005"
                    class="px-10 subtitle-2 text-capitalize"
                    :loading="formLoading"
                    type="submit"
                    :disabled="!validReply"
                    form="formReply"
                    >Kirim
                  </v-btn>
                </v-card-actions>
              </v-form>
            </template>
          </template>
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import MessageService from "@/services/resources/message.service";
import PublicService from "@/services/resources/public.service";
const FileDraggable = () => import("@/components/File/Draggable");
const LoaderNotFound = () => import("@/components/Loader/NotFound");
import {
  convertUrlToImageData,
  getBlobFromUrl,
  getFileExt
} from "@/util/convert.image.util";
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";

export default {
  components: {
    VueEditor,
    FileDraggable,
    LoaderNotFound
  },
  data: () => ({
    search: null,
    searchReceiver: null,
    valid: false,
    validReply: false,
    loading: false,
    formLoading: false,
    loadingReceiver: false,
    loadingDetail: false,
    height: 650,
    chat: null,
    selected: null,
    isCreate: false,
    isUpdate: false,
    isChange: true,
    isReply: false,
    form: {
      receivers: null,
      subject: null,
      content: null,
      attachment: null
    },
    pageStop: false,
    pagination: {
      page: 0,
      itemsPerPage: 10,
      totalItem: 0,
      pageCount: 0
    },
    type: null,
    types: [
      { text: "Kotak Masuk", value: "INBOX", new: 0 },
      { text: "Draft", value: "DRAFT", new: 0 },
      { text: "Terkirim", value: "PUBLISH", new: 0 },
      { text: "Terhapus", value: "DELETED", new: 0 }
    ],
    receivers: [],
    chats: [],
    message: {
      text: `<p>Anggy,</p>
        <p>Iste minus et. Non necessitatibus ut est est id amet. Officiis sequi dolorum assumenda 
        ipsam magnam cum possimus. Laudantium nulla amet tempore excepturi id expedita dolorum quisquam 
        deserunt. Odit vel sint dolor eos. Ea blanditiis animi. Quibusdam unde unde. Perspiciatis vel 
        pariatur qui. Deleniti omnis est quae. Laboriosam numquam amet aliquid.</p> 
        <p>Iste minus et. Non necessitatibus ut est est id amet. Officiis sequi dolorum assumenda ipsam magnam cum possimus. 
        Laudantium nulla amet tempore excepturi id expedita dolorum quisquam deserunt. Odit vel sint dolor 
        eos. Ea blanditiis animi. Quibusdam unde unde. Perspiciatis vel pariatur qui. Deleniti omnis est quae. 
        Laboriosam numquam amet aliquid.Iste minus et. Non necessitatibus ut est est id amet. Officiis sequi 
        dolorum.</p> 
        <p>Iste minus et. Non necessitatibus ut est est id amet. Officiis sequi dolorum assumenda ipsam 
        magnam cum possimus. Laudantium nulla amet tempore excepturi id expedita dolorum</p>`
    }
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    fetchChat() {
      return [this.search].join();
    }
  },
  watch: {
    isCreate(val) {
      if (!val) {
        this.form = {
          receivers: null,
          subject: null,
          content: null,
          attachment: null
        };
      }
    },
    isReply(val) {
      if (val) {
        this.form = {
          receivers: this.selected.sender,
          subject: "Re: " + this.selected.subject,
          content: null,
          attachment: null
        };
      } else {
        this.form = {
          receivers: null,
          subject: null,
          content: null,
          attachment: null
        };
      }
    },
    searchReceiver(val) {
      val && val !== this.receivers && this.getListReceiver(val);
    },
    chat(val) {
      console.log(val);
      if (val) {
        this.fetchListDebounce(() => this.getDetailMessage(val));
      }
    },
    type() {
      this.pagination.page = 1;
      this.search = "";
      this.pageStop = false;
      this.selected = null;
      this.fetchListDebounce(this.getListMessage);
    },
    fetchChat() {
      this.pagination.page = 1;
      this.pageStop = false;
      this.fetchListDebounce(this.getListMessage);
    }
  },
  methods: {
    handleClose() {
      this.isChange = false;
    },
    receiversChange(val) {
      this.fetchListDebounce(() => this.getListReceiver(val));
    },
    saveToDraft(type) {
      if (this.$refs[type == "reply" ? "formReply" : "form"].validate()) {
        let formData = new FormData();
        formData.append("id", 0);
        formData.append("subject", this.form.subject);
        formData.append("content", this.form.content);
        formData.append("status", "DRAFT");
        if (type == "reply") {
          formData.append("receivers[]", this.form.receivers.id);
        } else {
          this.form.receivers &&
            this.form.receivers.map(item => {
              formData.append("receivers[]", item);
            });
        }
        formData.append("attachment", this.form.attachment);
        this.saveMessage(formData, type);
      }
    },
    async save(type) {
      if (this.$refs[type == "reply" ? "formReply" : "form"].validate()) {
        // if (!this.form.attachment) {
        //   this.$refs.fileDraggable.openAlert("File harus diisi", "error");
        //   return;
        // }
        let formData = new FormData();
        formData.append("id", 0);
        formData.append("subject", this.form.subject);
        formData.append("content", this.form.content);
        formData.append("status", "PUBLISH");
        if (type == "reply") {
          formData.append("receivers[]", this.form.receivers.id);
        } else {
          this.form.receivers &&
            this.form.receivers.map(item => {
              formData.append("receivers[]", item);
            });
        }
        formData.append("attachment", this.form.attachment);
        this.saveMessage(formData, type);
      }
    },
    handleOnChange(val) {
      this.form.attachment = val;
    },
    onScroll(e) {
      if (
        !this.pageStop &&
        !this.loading &&
        e.target.scrollHeight > this.height &&
        e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight
      ) {
        this.pagination.page = this.pagination.page + 1;
        this.getListMessage("push");
      }
    },
    async handleSent(selected) {
      this.form.receivers = selected.receiver.reduce((p, c) => {
        p.push({
          id: c.id,
          username: c.name
        });
        return p;
      }, []);
      this.form.subject = selected.subject;
      this.form.content = selected.content;
      this.form.attachment = selected.attachment;
      if (this.form.attachment) {
        let url = this.form.attachment;
        let attachment = await convertUrlToImageData(url);
        let blob = await getBlobFromUrl(url);
        let filename = await getFileExt(url);
        this.form.attachment = new File([attachment], filename, {
          type: blob.type
        });
        this.isChange = false;
        this.isUpdate = true;
      } else {
        this.isChange = true;
        this.isUpdate = false;
      }
      this.isCreate = true;
    },
    handleDelete(id) {
      this.$confirm({
        title: "Confirm",
        message: `Are you sure you want to delete this message?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteMessage({ id: [id] });
          }
        }
      });
    },
    // Service
    async getListReceiver(id) {
      try {
        this.loadingReceiver = true;
        await PublicService.getUserSearchList(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.receivers = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingReceiver = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListMessage(type = "refresh") {
      try {
        const { page, itemsPerPage } = this.pagination;
        type == "refresh" ? (this.loading = true) : null;
        await MessageService[this.type == "INBOX" ? "getListInbox" : "getList"](
          {
            filter: {
              search: this.search,
              status: this.type
            },
            orderBy: ["id", "asc"],
            pageCurrent: page,
            dataPerPage: itemsPerPage
          }
        )
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let chats = list;
              if (list.length == 0) {
                this.pageStop = true;
              } else {
                chats.map((data, index) => {
                  data.no = itemsPerPage * (page - 1) + (index + 1);
                  if (this.type != "INBOX") {
                    data.sender = {
                      photo: this.currentUser.personal.photo,
                      name: this.currentUser.personal.nama,
                      division: this.currentUser.employee.jabatan_eselon.jabatan
                    };
                  }
                  return data;
                });
                if (type == "refresh") {
                  this.chats = chats;
                } else {
                  this.chats = [...this.chats, ...chats];
                }
                this.pagination.totalItem = parseInt(countTotal);
                this.pagination.pageCount = parseInt(pageTotal);
              }
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (type == "refresh" ? (this.loading = false) : null));
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailMessage(id) {
      try {
        this.loadingDetail = true;
        await MessageService[
          this.type == "INBOX" ? "getDetailInbox" : "getDetail"
        ](id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.selected = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingDetail = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveMessage(data, type) {
      try {
        this.formLoading = true;
        await MessageService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListMessage();
              if (type == "reply") {
                this.isReply = false;
              } else {
                this.isCreate = false;
              }
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteMessage(data) {
      try {
        this.formLoading = true;
        await MessageService[this.type == "INBOX" ? "deleteInbox" : "delete"](
          data
        )
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListMessage();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    console.log(this.currentUser);
  }
};
</script>
<style lang="scss">
#list-custom .v-list-item--link:before {
  background: inherit;
}
.caption.font-size-small {
  font-size: 0.55rem !important;
}
#card-custom .v-input {
  font-size: 0.775rem;
}
.ql-toolbar {
  color: #cccccc;
}
</style>
